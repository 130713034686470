




























































































































































































































































































































































































import ResourceDownloadsModal from '@/components/views/components/ResourceDownloadsModal.vue'
import config from '@/config'
import AvatarView from '@/components/views/AvatarView.vue'
import { Api, request, UserModel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    AvatarView,
    ResourceDownloadsModal
  }
})

export default class UsersView extends Mixins(ComponentHelper) {
    userData: UserModel[] = []
    searchPhrase = this.$route.query.q || ''
    loading: boolean = false
    selectedUser: UserModel | null = null
    showEditUser: boolean = false
    schoolId = this.$route.query.schoolid || ''
    resourceDownloadsModal: boolean = false
    resourceDownloadProps: any = {}

    mounted () {
      if (!this.$store.state.user.superuser) {
        this.$router.push('/noaccess')
      }
      if (this.$route.query.q || this.$route.query.schoolid) {
        this.searchDidChange()
      }
    }

    closeResourceDownloadsModal () {
      this.resourceDownloadsModal = false
    }

    getResourceDownloads (userId) {
      this.resourceDownloadProps = { userId }
      this.resourceDownloadsModal = true
    }

    setSchoolActive (user, school) {
      console.log(user.id)
      console.log(school)
      request('POST', 'superuser/schools/' + school + '/users/' + user.id + '/activate', null)
        .then((response) => {
          this.loading = false
          // var data = response.data
          this.searchDidChange()
          // console.log('readerData: ' + this.userData)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }

    removeUserFromSchool (user, school) {
      console.log(user.id)
      console.log(school)
      request('DELETE', 'superuser/schools/' + school + '/users/' + user.id, null)
        .then((response) => {
          this.loading = false
          // var data = response.data
          this.searchDidChange()
          // console.log('readerData: ' + this.userData)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }

    addUserToSchool (user) {
      const p = prompt('Add teacher to School ID?')
      if (p) {
        console.log(p)
        request('POST', 'superuser/schools/' + p + '/users/' + user.id, null)
          .then((response) => {
            this.loading = false
            // var data = response.data
            this.searchDidChange()
            // console.log('readerData: ' + this.userData)
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    }

    verifyEmail (email) {
      request('POST', 'superuser/verifyemail', { email })
        .then((response) => {
          this.loading = false
          // var data = response.data
          this.searchDidChange()
          // console.log('readerData: ' + this.userData)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }

    searchDidChange () {
      if (this.searchPhrase.length >= 1 || this.schoolId) {
        this.getUsers()
      }
    }

    getUsers () {
      this.loading = true
      const params: {q, schoolid} = { q: '', schoolid: undefined }
      if (this.searchPhrase) {
        params.q = this.searchPhrase
      }
      if (this.schoolId) {
        params.schoolid = this.schoolId
      }
      this.$router.replace({ name: 'Users', query: params })
      request('GET', 'users', params)
        .then((response) => {
          this.loading = false
          const data = response.data
          this.userData = data.users
          // console.log('readerData: ' + this.userData)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }

    editUser (user: UserModel) {
      this.selectedUser = user
      this.showEditUser = true
    }

    onCloseEditUser () {
      this.selectedUser = null
      this.showEditUser = false
    }

    setPassword (user) {
      const p = prompt('Enter new password.')
      console.log(p)
      if (p && p.length > 5) {
        request('PUT', 'superuser/users/' + user.id, { password: p })
          .then((response) => {
            const data = response.data
            if (data.error) {
              alert(data.error)
            } else {
              this.$buefy.toast.open('Success')
            }
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    }

    async setRole (userId, schoolId, role) {
      await Api.setRoleForSchoolUser(userId, schoolId, role)
      this.$buefy.toast.open('Success')
      this.getUsers()
    }

    deleteUser (user) {
      console.log('DELETE USER')
      const c = confirm('Are you sure you want to delete this user? This cannot be undone.')
      if (c) {
        request('DELETE', 'superuser/users/' + user.id, null)
          .then((response) => {
            const data = response.data
            if (data.error) {
              alert(data.error)
            } else {
              this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
            }
            this.getUsers()
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    }

    beforeSavePupil () {
      console.log(this.selectedUser)
      if (!this.selectedUser) {
        return
      }
      // validate + save
      // name, username, email format
      let ok = true
      if (this.selectedUser.name.length < 2) {
        ok = false
        alert('Name error')
      }

      if (this.selectedUser.username.length < 2) {
        ok = false
        alert('Name error')
      }

      if (ok) {
        const params: any = { name: this.selectedUser.name, username: this.selectedUser.username, locale: this.selectedUser.locale }
        if (this.selectedUser.email && this.selectedUser.email !== '') {
          params.email = this.selectedUser.email
        }
        if (this.selectedUser.display_name && this.selectedUser.display_name !== '') {
          params.display_name = this.selectedUser.display_name
        }
        if (this.selectedUser.upn && this.selectedUser.upn !== '') {
          params.upn = this.selectedUser.upn
        }
        request('PUT', 'superuser/users/' + this.selectedUser.id, params)
          .then((response) => {
            const data = response.data
            if (data.error) {
              alert(data.error)
            } else {
              this.$buefy.toast.open('Success')
              this.onCloseEditUser()
              this.searchDidChange()
            }
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    }

    immitateUser (userId) {
      window.location.href = `${config.authURI}immitateuser?user_id=${userId}`
    }

    async recalculateScoreCache (userId) {
      try {
        await Api.recacheUsersArchivedScores(userId)
        this.$buefy.toast.open({
          message: 'Updated archived scores',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.toast.open({
          message: 'Could not calculate scores',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }

    async recalculateTotalScores (userId) {
      try {
        await Api.recacheUsersTotalScores(userId)
        this.$buefy.toast.open({
          message: 'Updated total scores',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.toast.open({
          message: 'Could not calculate scores',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }

    async recalculateShedScores (userId) {
      try {
        await Api.recacheUsersShedScores(userId)
        this.$buefy.toast.open({
          message: 'Updated shed scores',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (err) {
        this.$buefy.toast.open({
          message: 'Could not calculate scores',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
}
